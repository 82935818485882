import { Box, Button, Divider, Flex, ScrollArea, TextInput, UnstyledButton } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconPlus, IconSearch } from '@tabler/icons-react';

import msg from '~/helpers/viewerInteractions/msg';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';
import { GetPageVariantData } from '~/services/PageSetServices/PageSetServices';

interface ManageVariantProps {
  onOpenAddVariantModal: () => void;
}

const ManageVariant = ({ onOpenAddVariantModal }: ManageVariantProps) => {
  const { variantList, getVariantView } = usePageSet();
  const [filter, setFilter] = useInputState('');

  const filteredVariantList = variantList
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((variant: { name: string }) =>
      variant.name.toLowerCase().includes(filter.toLowerCase()),
    );

  const handleClickToGetVariant = (variantNanoId: string) => () => {
    msg({ type: 'reset-bound' });
    getVariantView(variantNanoId);
  };

  return (
    <>
      <Box p={4}>
        <TextInput
          value={filter}
          onChange={setFilter}
          placeholder="Filter variants"
          leftSection={<IconSearch size={18} />}
          variant="filled"
        />
      </Box>

      <ScrollArea.Autosize mah={400} type="never" p={12}>
        {filteredVariantList.map((variant: GetPageVariantData) => (
          <Flex justify="space-between" align="center" key={variant.name}>
            <UnstyledButton
              p={4}
              fz={14}
              onClick={handleClickToGetVariant(variant.nanoId)}
              style={{ cursor: 'pointer', outlineOffset: '-2px' }}
            >
              {variant.name}
            </UnstyledButton>
          </Flex>
        ))}
      </ScrollArea.Autosize>

      <Divider />

      <Box p={12}>
        <Button w="100%" leftSection={<IconPlus />} onClick={onOpenAddVariantModal}>
          More variants
        </Button>
      </Box>
    </>
  );
};

export default ManageVariant;
