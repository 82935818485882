import { Affix, Flex, Paper, Text } from '@mantine/core';
import {
  IconArrowBarUp,
  IconPalette,
  IconPlayerPlay,
  IconPlus,
  IconWand,
} from '@tabler/icons-react';
import { useState } from 'react';

import BriefPanel from './BriefPanel/BriefPanel';
import { ButtonContent, StyledButton } from './Toolbar.styles';
import useToolbarOperations, { toolbarOptions } from './useToolbarOperations';

import { MakeOpenSubPanelObj, ToolbarOptionTypes } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';

interface ToolbarProps {
  showBriefPanelOnly: boolean;
}

const Toolbar = ({ showBriefPanelOnly }: ToolbarProps) => {
  const [openPanel, setOpenPanel] = useState<ToolbarOptionTypes>();
  const [makeOpenSubPanel, setMakeOpenSubPanel] = useState<MakeOpenSubPanelObj>({});

  // If you want to extend the toolbar with new second-row-options or panels,
  // or add a trigger to programmatically operating toolbar's behaviour,
  // ToolbarOperation.tsx is the place to look for
  useToolbarOperations(setOpenPanel, setMakeOpenSubPanel);

  const handleClickToOpenPanel = (panelName: ToolbarOptionTypes) => {
    setOpenPanel(openPanel === panelName ? undefined : panelName);
    setMakeOpenSubPanel({});
  };

  const shouldExpandMenu = ['add-element', 'optimize-page'].includes(openPanel || '');

  if (showBriefPanelOnly) {
    return (
      <Affix position={{ right: 8, top: 65 }} zIndex={9999}>
        <Paper style={{ width: '412px', height: 0 }}>
          <BriefPanel key={'brief-panel'} opened={openPanel === 'brief-panel'} />
        </Paper>
      </Affix>
    );
  }

  return (
    <Affix position={{ right: 8, top: 8 }} zIndex={9999}>
      <Paper
        shadow="xs"
        p="4"
        withBorder
        radius={8}
        style={{ width: '412px', height: shouldExpandMenu ? '110px' : '58px' }}
      >
        <Flex align={'center'} gap={4} p="0">
          <StyledButton
            onClick={() => handleClickToOpenPanel('add-element')}
            variant="transparent"
            color="Gray/Gray 8"
            className={'add-element' === openPanel ? 'opened' : ''}
          >
            <ButtonContent>
              <IconPlus size={20} />
              <Text size="xs" fw={500}>
                Add
              </Text>
            </ButtonContent>
          </StyledButton>
          <StyledButton
            variant="transparent"
            color="Gray/Gray 8"
            onClick={() => handleClickToOpenPanel('style-panel')}
            className={'style-panel' === openPanel ? 'panel-opened' : ''}
          >
            <ButtonContent>
              <IconPalette size={20} />
              <Text size="xs" fw={500}>
                Style
              </Text>
            </ButtonContent>
          </StyledButton>
          <StyledButton variant="transparent" color="Gray/Gray 8" className="d-none">
            <ButtonContent>
              <IconWand size={20} />
              <Text size="xs" fw={500}>
                Enhance
              </Text>
            </ButtonContent>
          </StyledButton>

          <StyledButton
            variant="transparent"
            color="Gray/Gray 8"
            onClick={() => {
              msg({ type: 'editor-sync' });
              window.open('./preview', '_newtab');
            }}
          >
            <ButtonContent>
              <IconPlayerPlay size={20} />
              <Text size="xs" fw={500}>
                Preview
              </Text>
            </ButtonContent>
          </StyledButton>
          <StyledButton
            variant="transparent"
            color="Gray/Gray 8"
            onClick={() => handleClickToOpenPanel('publish-panel')}
            className={'publish-panel' === openPanel ? 'panel-opened' : ''}
          >
            <ButtonContent>
              <IconArrowBarUp size={20} />
              <Text size="xs" fw={500}>
                Publish
              </Text>
            </ButtonContent>
          </StyledButton>
        </Flex>

        {Object.entries(toolbarOptions).map(([key, obj]) => {
          const Option = obj.component;
          return (
            <Option
              key={key}
              opened={openPanel === key}
              makeOpenSubPanel={makeOpenSubPanel}
              onClickToClose={() => setOpenPanel(undefined)}
            />
          );
        })}
      </Paper>
    </Affix>
  );
};

export default Toolbar;
