import { Box, Button, Divider, Modal, Popover, Text } from '@mantine/core';
import { IconLayersDifference } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import AddVariant from './AddVariant/AddVariant';
import DefineVariant from './DefineVariant/DefineVariant';
import GeneratingVariantLightbox from './GeneratingVariantLightbox/GeneratingVariantLightbox';
import ManageVariant from './ManageVariant/ManageVariant';

import { IconRegenVariants } from '~/components/CustomIcons/IconRegenVariants';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';

const variantButtonCommonProps = {
  variant: 'light',
  bg: '#F2EAFF',
  radius: 4,
  miw: 72,
  maw: 200,
};

const VariantMenuExtension = () => {
  const { isLoading, variantList, selectedVariant, resetPageSetContext } = usePageSet();
  const [openedAddVariantModal, setOpenedAddVariantModal] = useState(false);

  useEffect(() => () => resetPageSetContext(), []);

  const [openedModal, setOpenedModal] = useState(false);

  const handleClickToOpenModal = () => {
    setOpenedModal(true);
  };

  const handleCloseModal = () => {
    setOpenedModal(false);
  };

  const handleClickToOpenAddVariantModal = () => {
    setOpenedAddVariantModal(true);
  };

  const handleCloseAddVariantModal = () => {
    setOpenedAddVariantModal(false);
  };

  const noVariants = variantList.length === 0;

  const button =
    !isLoading && noVariants ? (
      <Button leftSection={<IconLayersDifference />} radius={4} onClick={handleClickToOpenModal}>
        Create variant
      </Button>
    ) : isLoading ? (
      <Button loading={isLoading} loaderProps={{ type: 'dots' }} {...variantButtonCommonProps} />
    ) : (
      <Popover width={300} trapFocus position="bottom" withArrow withinPortal={false} shadow="md">
        <Popover.Target>
          <Button {...variantButtonCommonProps} leftSection={<IconLayersDifference />}>
            <Text truncate="end">{selectedVariant?.name}</Text>
          </Button>
        </Popover.Target>
        <Popover.Dropdown p={0}>
          <ManageVariant onOpenAddVariantModal={handleClickToOpenAddVariantModal} />
        </Popover.Dropdown>
      </Popover>
    );

  return (
    <>
      <Divider orientation="vertical" />
      <Box px={8}>{button}</Box>

      <Divider orientation="vertical" />
      <Box px={8}>
        <Button leftSection={<IconRegenVariants />} disabled>
          0 Edits
        </Button>
      </Box>

      <Modal
        opened={openedModal}
        onClose={handleCloseModal}
        title={<b style={{ fontSize: '1.5rem' }}>Create variant</b>}
        size="35%"
        zIndex={99999}
        centered
      >
        <DefineVariant onClose={handleCloseModal} />
      </Modal>

      <GeneratingVariantLightbox />
      <Modal
        opened={openedAddVariantModal}
        onClose={handleCloseAddVariantModal}
        title={<b style={{ fontSize: '1.5rem' }}>Add more variants</b>}
        size="35%"
        zIndex={99999}
        centered
      >
        <AddVariant onClose={handleCloseAddVariantModal} />
      </Modal>
    </>
  );
};

export default VariantMenuExtension;
