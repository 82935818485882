import { Button, Popover, PopoverWidth, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import React, { useState } from 'react';

import ListVariant from '../ListVariant';

import IconVariant from '~/components/CustomIcons/IconVariant';
import { GetPageVariantData } from '~/services/PageSetServices/PageSetServices';

interface VariantDropdownButtonProps {
  isLoading?: boolean;
  variantList: GetPageVariantData[];
  popoverWidth: PopoverWidth;
  selectedVariantName?: string;
}

const VariantDropdownButton: React.FC<VariantDropdownButtonProps> = ({
  isLoading,
  variantList,
  popoverWidth,
  selectedVariantName,
}) => {
  const [opened, setOpened] = useState(false);

  const variantButtonCommonProps = {
    variant: 'light',
    color: opened ? '' : 'black',
    bg: opened ? '#F2EAFF' : 'white',
    radius: 4,
    miw: 72,
    maw: 200,
  };

  return isLoading ? (
    <Button loading={isLoading} loaderProps={{ type: 'dots' }} {...variantButtonCommonProps} />
  ) : (
    <Popover
      width={popoverWidth}
      trapFocus
      position="bottom"
      withArrow
      withinPortal={false}
      shadow="md"
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <Button
          {...variantButtonCommonProps}
          leftSection={<IconVariant />}
          rightSection={<IconChevronDown />}
          onClick={() => {
            setOpened((o) => !o);
          }}
        >
          <Text truncate="end">{selectedVariantName}</Text>
        </Button>
      </Popover.Target>
      <Popover.Dropdown p={0}>
        <ListVariant variantList={variantList} />
      </Popover.Dropdown>
    </Popover>
  );
};

export default VariantDropdownButton;
