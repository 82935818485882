import { MutationBehavior } from 'use-undoable';

import addPageData from './pageProcessing/addPageData/addPageData';
import addSection from './pageProcessing/addSection/addSection';
import imageSwapUploadState from './pageProcessing/imageSwapUploadState/imageSwapUploadState';
import { imageAttachState } from './pageProcessing/imageUploadAndAttach/imageUploadAndAttach';
import modifyPageData from './pageProcessing/modifyPageData/modifyPageData';
import modifyPublishSettings from './pageProcessing/modifyPublishSettings/modifyPublishSettings';
import placeholderToImage from './pageProcessing/placeholderToImage/placeholderToImage';
import removeAllUploadState from './pageProcessing/removeUploadState/removeUploadState';

import { LandingpageDetails, PageJsonSnippetObj } from '~/global.types';
import { findPageData } from '~/helpers/pageDataTraverse/pageDataTraverse';
import msg from '~/helpers/viewerInteractions/msg';
import { StudioMessageCollection } from '~/messages.types';

let cachedPageDataForSaveLater: string | undefined;

type AppMessageHandlingDataParser = [
  string,
  (
    payload: string | ((oldValue: string) => string),
    behavior?: MutationBehavior | undefined,
    ignoreAction?: boolean | undefined,
  ) => void,
  LandingpageDetails,
  React.Dispatch<React.SetStateAction<LandingpageDetails>>,
  string[],
  React.Dispatch<React.SetStateAction<number>>,
];

const AppMessageHandling = (
  { data }: MessageEvent<StudioMessageCollection>,
  [
    pageData,
    setPageData,
    pageDetails,
    setPageDetails,
    past,
    triggerInitialise,
  ]: AppMessageHandlingDataParser,
) => {
  const { type } = data;

  if (type === 'trigger-broadcast-page-data') {
    msg({ type: 'broadcast-page-data', pageData, pageDetails });
  }

  if (type === 'trigger-broadcast-inline-edit-page-data') {
    const pageDataToBeSend = cachedPageDataForSaveLater || pageData;
    msg({ type: 'broadcast-inline-edit-page-data', pageData: pageDataToBeSend, pageDetails });
  }

  if (type === 'head-edited') {
    const modifiedDataObject = {
      ...JSON.parse(pageData),
      head: data.head,
    };
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
  }

  if (type === 'editing-performed') {
    const blockData = data.outputData.blocks[0];
    const modifiedDataObject = modifyPageData(
      JSON.parse(cachedPageDataForSaveLater || pageData),
      blockData || { id: data.fromSelector, toDelete: true },
    );
    cachedPageDataForSaveLater = JSON.stringify(modifiedDataObject, null, 2);
    msg({ type: 'update-bound' });
  }

  if (type === 'editing-performed-from-panel') {
    const modifiedDataObject = modifyPageData(JSON.parse(pageData), data.outputData);
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
    msg({ type: 'update-bound' });
  }

  if (type === 'editing-publish-settings') {
    const modifiedDataObject = modifyPublishSettings(JSON.parse(pageData), data.outputData);
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
    setPageDetails({
      ...pageDetails,
      definition: data.outputData.urlSlug || '',
      gtmId: data.outputData.googleTagManagerId || '',
      description: data.outputData.description || '',
      favicon: data.outputData.favicon || '',
    });
  }

  // Another converaging target. Need to test it whether we need this anymore
  if (type === 'editor-sync' && cachedPageDataForSaveLater) {
    setPageData(cachedPageDataForSaveLater);
    cachedPageDataForSaveLater = undefined;
  }

  if (type === 'dev-editor-sync') {
    setPageData(data.htmlStr as string);
    msg({ type: 'update-bound' });
  }

  if (type === 'delete-page-elements') {
    let modifiedDataObject = JSON.parse(pageData);
    data.delete.forEach((del: string) => {
      modifiedDataObject = modifyPageData(modifiedDataObject, {
        id: del,
        toDelete: true,
      });
    });
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
  }

  if (type === 'add-page-element') {
    const modifiedDataObject = addPageData(JSON.parse(pageData), data);
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
    msg({ type: 'update-bound' });
  }

  if (type === 'move-page-element') {
    const modifiedDataObject = addPageData(JSON.parse(pageData), data);
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
    msg({ type: 'update-bound' });
  }

  if (type === 'add-section') {
    const modifiedDataObject = addSection(JSON.parse(pageData), data);
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
  }

  if (type === 'move-element-in-new-section') {
    const addNode = findPageData(JSON.parse(pageData), data.selector);
    const modifiedDataObject = modifyPageData(JSON.parse(pageData), {
      id: data.selector,
      toDelete: true,
    });

    const result = addSection(modifiedDataObject, {
      type: 'add-section',
      messageData: data.messageData,
      containsToBeAdded: [
        {
          ...addNode,
          '@slot': 'column-1',
        } as PageJsonSnippetObj,
      ],
    });
    setPageData(JSON.stringify(result, null, 2));
  }

  if (type === 'move-page-section') {
    const objectToBeAdded = findPageData(JSON.parse(pageData), data.selector);
    const modifiedDataObject = modifyPageData(JSON.parse(pageData), {
      id: data.selector,
      toDelete: true,
    });
    const result = addSection(modifiedDataObject, {
      type: 'add-section',
      messageData: { elementSelector: data.destination },
      objectToBeAdded,
    });
    setPageData(JSON.stringify(result, null, 2));
  }

  if (type === 'get-selected-page-data') {
    const { elementSelector, messageData, openToolbarPanel, openToolbarSubPanel } = data;
    const result = findPageData(JSON.parse(pageData), elementSelector);
    msg({
      type: 'receive-selected-page-data',
      result,
      openToolbarPanel,
      openToolbarSubPanel,
      viewerData: messageData.data,
    });
  }

  if (type === 'replace-styles') {
    const modifiedDataObject = modifyPageData(JSON.parse(pageData), data);
    setPageData(JSON.stringify(modifiedDataObject, null, 2));
    msg({ type: 'update-bound' });
  }

  if (type === 'add-image-placeholder-while-uploading') {
    const modifiedDataObject = addPageData(JSON.parse(pageData), data);

    if (data.addNewCol) {
      setPageData(JSON.stringify(modifiedDataObject, null, 2));
    } else {
      imageAttachState(data);
      cachedPageDataForSaveLater = JSON.stringify(modifiedDataObject, null, 2);
    }
  }

  if (type === 'replace-and-upload-image') {
    const modifiedDataObject = modifyPageData(JSON.parse(pageData), {
      id: data.selector,
      data: {
        dataImageSrc: data.imageSrc,
      },
    });
    imageSwapUploadState(data.selector);
    cachedPageDataForSaveLater = JSON.stringify(modifiedDataObject, null, 2);
  }

  if (type === 'image-upload-complete') {
    const modifiedDataObject = placeholderToImage(
      JSON.parse(cachedPageDataForSaveLater || pageData),
      data.uploaded,
    );

    if (cachedPageDataForSaveLater) {
      setPageData(JSON.stringify(modifiedDataObject, null, 2));
      cachedPageDataForSaveLater = undefined;
    } else {
      setPageData(JSON.stringify(modifiedDataObject, null, 2), undefined, true);
    }
  }

  if (type === 'restore-page-when-error') {
    if (cachedPageDataForSaveLater) {
      cachedPageDataForSaveLater = undefined;
      removeAllUploadState();
    } else {
      // Drop to add image to new section or new column hasn't converted to use cachedPageDataForSaveLater yet
      setPageData(past[past.length - 1], undefined, true);
    }
  }

  if (type === 'full-page-reinit') {
    triggerInitialise(new Date().valueOf());
  }
};

export default AppMessageHandling;
