import { notifications } from '@mantine/notifications';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import updatePageContentWithVariant from './updatePageContentWithVariant';

import { LandingpageDetails, ViewerWindowObject } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import useCommonWebSocket from '~/hooks/useCommonWebSocket/useCommonWebSocket';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import {
  createPageSet,
  createPageVariants,
  getPageSet,
  GetPageSetData,
  GetPageVariantData,
  getPageVariants,
  GetPageVariantsResponse,
  getVariant,
} from '~/services/PageSetServices/PageSetServices';

type PageSetContextData = GetPageVariantsResponse['data'];

type PageSetContextType = null | {
  isGenerating?: boolean;
  isLoading?: boolean;
  pageSetId?: string;
  variantList: GetPageVariantData[];
  selectedVariant?: GetPageVariantData;
  getVariantView: (nanoId: string) => void;
  generateVariants: (variantName: string, variantKeywords: string[]) => void;
  addVariants: (variantKeywords: string[]) => void;
  resetPageSetContext: () => void;
};

const PageSetContext = createContext<PageSetContextType>(null);

// eslint-disable-next-line react-refresh/only-export-components
export const usePageSet = () => {
  const context = useContext(PageSetContext);
  if (!context) {
    throw new Error('usePageSet must be used within a PageSetProvider');
  }
  return context;
};

export const PageSetProvider = ({ children }: PropsWithChildren) => {
  const { receive } = useCommonWebSocket();

  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [variantList, setVariantList] = useState<PageSetContextData>([]);
  const [selectedVariant, setSelectedVariant] = useState<GetPageVariantData>();
  const [pageDetails, setPageDetails] = useState<Partial<LandingpageDetails>>({});
  const { pageSetId, workspaceId, nanoId: pageNanoId } = pageDetails;

  const getVariantView = async (nanoId: string) => {
    setIsLoading(true);
    return await getVariant({ nanoId })
      .then((res) => {
        setSelectedVariant(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createVariantsPattern = async (
    pageSetNanoId: string,
    variantName: string,
    variantKeywords: string[],
  ) => {
    const { data: newPageVariantList } = await createPageVariants({
      pageSetId: pageSetNanoId,
      variantName,
      variantKeywords,
    });
    setVariantList((prevList) => [...prevList, ...newPageVariantList]);

    // This will be replaced by subscribe pattern once backend completes
    // the notification work
    if (receive) console.log(receive?.connectionId);
    setTimeout(() => {
      msg({ type: 'full-page-reinit' });

      setTimeout(() => {
        setIsGenerating(false);
      }, 3000);
    }, 20000);

    // To be worked on UPF-2515
  };

  const addVariants = async (variantKeywords: string[]) => {
    if (!workspaceId || !pageNanoId || !pageSetId) return;
    setIsGenerating(true);
    const pageSetData: GetPageSetData = await getPageSet({ nanoId: pageSetId });
    createVariantsPattern(pageSetId, pageSetData.definition, variantKeywords);
  };

  const generateVariants = async (variantName: string, variantKeywords: string[]) => {
    if (!workspaceId || !pageNanoId) return;
    setIsGenerating(true);
    const { data: pageSetData } = await createPageSet({
      workspaceId,
      pageNanoId,
      variantStrategy: variantName,
    });
    setPageDetails({
      ...pageDetails,
      pageSetId: pageSetData.nanoId,
    });
    createVariantsPattern(pageSetData.nanoId, variantName, variantKeywords);
  };

  const resetPageSetContext = () => {
    setIsLoading(true);
    setVariantList([]);
    setPageDetails({});
    setSelectedVariant(undefined);
  };

  useViewerMessage(
    ({ data }) => {
      if (data.type === 'first-fetch-page-data-completed') {
        setPageDetails(data.pageDetails);
        const { pageSetId: pId } = data.pageDetails;

        if (pId) {
          getPageVariants({ pageSetId: pId })
            .then((res) => {
              setVariantList(res.data);
              if (res.data[0]) getVariantView(res.data[0].nanoId);
            })
            .catch(() => {
              notifications.show({
                color: 'red',
                message: 'Failed to retrieve page variants.',
                autoClose: 3000,
              });
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      }

      if (data.type === 'viewer-refreshed') {
        const viewer = document.querySelector(`iframe#${data.viewer}`);
        if (viewer && selectedVariant)
          updatePageContentWithVariant(viewer as HTMLIFrameElement, selectedVariant.variantNodes);
      }

      if (data.type === 'reset-bound') {
        document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
          (
            (viewer as HTMLIFrameElement).contentWindow as ViewerWindowObject
          )?.revertCurrentEditorToUnit();
        });
      }
    },
    [selectedVariant],
  );

  useEffect(() => {
    if (selectedVariant) {
      document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
        updatePageContentWithVariant(viewer as HTMLIFrameElement, selectedVariant.variantNodes);
      });
    }
  }, [selectedVariant]);

  return (
    <PageSetContext.Provider
      value={{
        isGenerating,
        isLoading,
        pageSetId,
        variantList,
        selectedVariant,
        getVariantView,
        generateVariants,
        addVariants,
        resetPageSetContext,
      }}
    >
      {children}
    </PageSetContext.Provider>
  );
};
