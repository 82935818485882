import seperateStyleFromInnerHtml from '~/helpers/viewerInteractions/seperateStyleFromInnerHtml';
import { GetPageVariantData } from '~/services/PageSetServices/PageSetServices';

const updatePageContentWithVariant = (
  viewer: HTMLIFrameElement,
  variantNodes: GetPageVariantData['variantNodes'],
) => {
  if (!variantNodes) return;

  const variantNodeKeys = Object.keys(variantNodes);

  variantNodeKeys.forEach((key: string) => {
    const variantEnabledElem = viewer.contentDocument?.querySelector(
      `[data-variant-node-id="${key}"]`,
    );

    if (variantEnabledElem) {
      // If EditorJS is already appended, we don't need to seperate out the styles for innerHTML swap
      const foundEditorAppended = variantEnabledElem.querySelector('.upf-unit-edit');

      if (foundEditorAppended) {
        foundEditorAppended.innerHTML = variantNodes[key].textValue;
      } else {
        const original = seperateStyleFromInnerHtml(variantEnabledElem.outerHTML);
        variantEnabledElem.innerHTML = original.styles + variantNodes[key].textValue;
      }
    }
  });
};

export default updatePageContentWithVariant;
