import { awsAPIClient } from '~/helpers/ApiClient';

export interface CreatePageSetResponse {
  message: string;
  data: {
    definition: string;
    nanoId: string;
  };
}

export const createPageSet = async ({
  workspaceId,
  pageNanoId,
  variantStrategy,
}: {
  workspaceId: string;
  pageNanoId: string;
  variantStrategy: string;
}) => {
  return awsAPIClient.post<CreatePageSetResponse>('/create-page-set', {
    workspaceId,
    pageNanoId,
    variantStrategy,
  });
};

export const createPageVariants = async ({
  pageSetId,
  variantName,
  variantKeywords,
}: {
  pageSetId: string;
  variantName: string;
  variantKeywords: string[];
}) => {
  if (!pageSetId || !variantName || !variantKeywords)
    console.error('You need pageSetId, variantName and variantKeywords to create page variants.');

  return awsAPIClient.post<GetPageVariantsResponse>('/create-page-variants', {
    pageSetId,
    variantName,
    variantKeywords,
  });
};

export interface GetPageVariantData {
  name: string;
  nanoId: string;
  variantNodes?: Record<string, { textValue: 'string'; type: string }>;
}
export interface GetPageVariantsResponse {
  count: number;
  data: GetPageVariantData[];
}

export interface GetPageSetData {
  definition: string;
}

export const getPageVariants = async ({ pageSetId }: { pageSetId: string }) => {
  return awsAPIClient.post<GetPageVariantsResponse>('/get-page-variants', {
    pageSetId,
  });
};

export const getVariant = async ({ nanoId }: { nanoId: string }) => {
  return awsAPIClient.post<GetPageVariantData>('/get-page-variant', {
    nanoId,
  });
};

export const getPageSet = async ({ nanoId }: { nanoId: string }) => {
  return awsAPIClient.post<GetPageSetData>('/get-page-set', {
    nanoId,
  });
};
