import { ActionIcon } from '@mantine/core';
import { useEffect, useState } from 'react';

import { OverlayVariantRegenIndicator } from '../PannableOverlays.styles';
import {
  OverlayBoundRect,
  pinPointerFromElementBoundInViewer,
  transformPointerToElementBound,
} from '../SetBoundForOverlayElements';

import { IconRegenVariants } from '~/components/CustomIcons/IconRegenVariants';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';

interface VariantNodeOverlayProps {
  show: boolean;
}

type OverlayData = {
  id: string;
};

// Export this out only for mocking in the test
// eslint-disable-next-line react-refresh/only-export-components
export const setPins = (): OverlayBoundRect<OverlayData>[] => {
  const viewer = document.querySelector<HTMLIFrameElement>('iframe[id^="iFrameResizer"]');
  if (viewer) {
    const variantNodes = viewer.contentDocument?.querySelectorAll('[data-variant-node-id]') ?? [];
    const domRects = [...variantNodes].reduce(
      (arr: OverlayBoundRect<OverlayData>[], curr: Element) => {
        const pinId = `variant-node-${curr.id}`;
        const id = curr.getAttribute('data-variant-node-id') || '';
        pinPointerFromElementBoundInViewer(pinId, viewer.id, curr.getBoundingClientRect());
        transformPointerToElementBound(pinId, { id }, (result: OverlayBoundRect<OverlayData>) => {
          arr.push(result);
        });

        return arr;
      },
      [],
    );

    return domRects;
  }

  return [];
};

const VariantNodeOverlay = ({ show }: VariantNodeOverlayProps) => {
  const [bounds, setBounds] = useState<OverlayBoundRect<OverlayData>[]>([]);
  const { selectedVariant } = usePageSet();

  useEffect(() => {
    setBounds(setPins());
  }, [selectedVariant, show]);

  return bounds.map(({ data, x, y, width }) => {
    const withinScreenX = x >= 0 && x <= window.innerWidth;
    const withinScreenY = y >= 0 && y <= window.innerHeight;

    return !withinScreenX || !withinScreenY ? null : (
      <OverlayVariantRegenIndicator
        key={data.id}
        style={{
          left: x + width + 4,
          top: y,
        }}
      >
        <ActionIcon variant="outline" color="violet">
          <IconRegenVariants />
        </ActionIcon>
      </OverlayVariantRegenIndicator>
    );
  });
};

export default VariantNodeOverlay;
