import { ActionIcon } from '@mantine/core';
import { IconLayersDifference } from '@tabler/icons-react';

const IconVariant = ({ size }: { size?: number }) => {
  return (
    <ActionIcon
      size={size}
      style={{
        background: 'linear-gradient(45deg, #4a4fd9, #d93ae0)',
        mask: 'url(#mask-layer)',
        masksize: 'contains',
      }}
      component="div"
    >
      <svg width="0" height="0">
        <mask id="mask-layer">
          <rect width="100%" height="100%" />
          <IconLayersDifference size={size} />
        </mask>
      </svg>
    </ActionIcon>
  );
};

export default IconVariant;
