import styled from 'styled-components';

export const FullScreenOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 995;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
`;

export const OverlayHeading = styled.div`
  position: fixed;
  user-select: none;
  pointer-events: all;
  z-index: 996;
  font-size: 13px;
  color: #888;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const OverlayVariantRegenIndicator = styled.div`
  position: fixed;
  color: #6931ed;
  z-index: 998;
`;

export const BoundingBoxSupportingText = styled.div`
  position: fixed;
  color: #6931ed;
  pointer-events: none;
  z-index: 996;
  margin-top: -16px;
  text-transform: capitalize;
  font-size: 12px;
`;

export const ElementClickedBoundBox = styled.div`
  position: fixed;
  border: 1px solid #6931ed;
  pointer-events: none;
  z-index: 996;
`;

export const ElementHoverAreaGuideDecor = styled.div`
  position: fixed;
  border: 1px dashed #6931ed;
  width: 100%;
  height: 100%;
  background: rgba(105, 49, 237, 0.02);
  pointer-events: none;
  z-index: 996;
`;

export const ElementHoverDropline = styled.div`
  position: fixed;

  background: #6931ed;
  pointer-events: all;
  opacity: 0;
  z-index: 997;
  border-bottom: 1px dashed #6931ed44;

  &.is-hovered {
    opacity: 1;
  }
`;

export const ElementHoverImageElement = styled.div`
  position: fixed;
  border: 1px dashed #d5c2ff;
  background: rgba(105, 49, 237, 0.4);
  z-index: 998;
  opacity: 0;
  pointer-events: none;

  &.is-hovered {
    opacity: 1;
  }
`;

// Useful for selection buffer zone - especially useful for droplines
// so that user don't have to worry about hand-accuracy pointing
// to a thin line
export const InvisibleLayer = styled.div`
  position: fixed;
  background: transparent;
  pointer-events: all;
`;
