import Userfront from '@userfront/toolkit';
import useWebSocket from 'react-use-websocket';

export interface WebSocketConnectMessage {
  connectionId: string;
}

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_URL;

const useCommonWebSocket = () => {
  const jwtToken = Userfront.tokens.accessToken;

  const { lastJsonMessage, sendJsonMessage, readyState } = useWebSocket<WebSocketConnectMessage>(
    WEBSOCKET_URL,
    {
      share: true,
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 5000,
      protocols: jwtToken,
    },
  );

  return { receive: lastJsonMessage, send: sendJsonMessage, ready: readyState };
};

export default useCommonWebSocket;
