import { Button, Divider, Flex, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPlus } from '@tabler/icons-react';

import { validateKeywords } from '../DefineVariant/validate';

import { VARIANTS_KEYWORDS_LIMIT } from '~/constants';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';

interface AddVariantProps {
  onClose: () => void;
}

const amountOfKeywords = (keywords: string): number =>
  keywords.length === 0 ? 0 : keywords.split(',').length;

const AddVariant = ({ onClose }: AddVariantProps) => {
  const { addVariants, variantList } = usePageSet();
  const variantListAsString = variantList.map((item) => item.name).join(', ');

  const form = useForm({
    initialValues: {
      keywords: '',
    },
    validate: {
      keywords: (value) => validateKeywords(variantListAsString + (value ? `, ${value}` : '')),
    },
    validateInputOnChange: true,
  });

  const handleClickToAddVariant = () => {
    const { keywords } = form.values;
    addVariants(keywords.split(',').map((item) => item.trim()));
    onClose();
    // To be worked on UPF-2429
  };

  return (
    <>
      <Textarea
        mt={16}
        mb={4}
        rows={8}
        placeholder="e.g. New York, San Francisco, Los Angeles"
        {...form.getInputProps('keywords')}
      />

      {form.isValid('keywords') && (
        <Text fz={12} ta="right" c="var(--mantine-color-dimmed)">
          {amountOfKeywords(
            variantListAsString + (form.values.keywords ? `, ${form.values.keywords}` : ''),
          )}
          /{VARIANTS_KEYWORDS_LIMIT} keywords used
        </Text>
      )}

      <Divider mx={-16} mt={32} />

      <Flex mt={16} gap={10}>
        <Button onClick={onClose} variant="default" type="button">
          Cancel
        </Button>
        <Button
          leftSection={<IconPlus />}
          disabled={!form.isValid()}
          onClick={handleClickToAddVariant}
        >
          Generate variants
        </Button>
      </Flex>
    </>
  );
};

export default AddVariant;
