import { Group, List, Loader, Modal, Stack, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';

const GeneratingVariantLightbox = () => {
  const { isGenerating } = usePageSet();
  return (
    <Modal
      onClose={() => null}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      opened={isGenerating || false}
      size={420}
      zIndex={99999}
      centered
      radius={8}
    >
      <Stack align="center" p={36} gap={0}>
        <img src="/assets/generating-variants.svg" />

        <Group my={24} gap={8}>
          <Loader size={14} />
          <Text fz={12} fw={600}>
            Generating your page variants
          </Text>
        </Group>

        <Text mb={8} w="100%" fw={600} fz={12}>
          Did you know?...
        </Text>

        <List
          fz={12}
          spacing={8}
          icon={<IconCheck size={16} color="var(--mantine-color-upflowy-purple-filled)" />}
        >
          <List.Item>
            You can always refine your variants later to better target specific audiences.
          </List.Item>
          <List.Item>
            Each variant should have unique content tailored to speak directly to its intended
            audience for better impact.
          </List.Item>
          <List.Item>
            Using high-quality images and clear calls-to-action can significantly boost engagement
            on each variant.
          </List.Item>
        </List>
      </Stack>
    </Modal>
  );
};

export default GeneratingVariantLightbox;
