import AddElementMenu from './AddElementMenu/AddElementMenu';
import AddElementPanel from './AddElementPanel/AddElementPanel';
import AddImagePanel from './AddImagePanel/AddImagePanel';
import AddSectionPanel from './AddSectionPanel/AddSectionPanel';
import AddTextPanel from './AddTextPanel/AddTextPanel';
import BackgroundPanel from './BackgroundPanel/BackgroundPanel';
import BriefPanel from './BriefPanel/BriefPanel';
import PublishPanel from './PublishPanel/PublishPanel';
import SelectedButtonPanel from './SelectedButtonPanel/SelectedButtonPanel';
import SelectedImagePanel from './SelectedImagePanel/SelectedImagePanel';
import StyleInspectorPanel from './StyleInspectorPanel/StyleInspectorPanel';
import StylePanel from './StylePanel/StylePanel';

import { MakeOpenSubPanelObj, SubPanelTypes, ToolbarOptionTypes } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import { postMessagesToParent } from '~/helpers/viewerInteractions/postMessageOnParentTrigger';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { StudioMessage } from '~/messages.types';

/**
 * Toolbar second row menu options - e.g. menu options under "Add" button
 * The main options (first row) is all inside Toolbar component itself
 */
export const toolbarOptions = {
  'add-element': {
    component: AddElementMenu,
  },
  'brief-panel': {
    component: BriefPanel,
  },
  'publish-panel': {
    component: PublishPanel,
  },
  'style-panel': {
    component: StylePanel,
  },
  'style-inspector-panel': {
    component: StyleInspectorPanel,
  },
};

/**
 * The panels triggered from second row options
 */
export const subPanelCollection = {
  'add-text': {
    component: AddTextPanel,
  },
  'add-image': {
    component: AddImagePanel,
  },
  'selected-image-panel': {
    component: SelectedImagePanel,
  },
  'add-element': {
    component: AddElementPanel,
  },
  'add-section': {
    component: AddSectionPanel,
  },
  'selected-button-panel': {
    component: SelectedButtonPanel,
  },
  'background-setting': {
    component: BackgroundPanel,
  },
};

let selected: Omit<StudioMessage['GetSelectedPageData'], 'type'>;
let prepareClosePanelAfterViewerFullyRefreshed = false;

/**
 * Triggers captured from viewer that opens up the toolbar panels
 */
const useToolbarOperations = (
  setOpenPanel: React.Dispatch<React.SetStateAction<ToolbarOptionTypes>>,
  setMakeOpenSubPanel: React.Dispatch<React.SetStateAction<MakeOpenSubPanelObj>>,
) =>
  useViewerMessage(({ data }) => {
    if (data.type === 'element-selected-in-viewer') {
      let openToolbarPanel: ToolbarOptionTypes;
      let openToolbarSubPanel: SubPanelTypes;

      if (selected?.openToolbarSubPanel === 'background-setting') {
        openToolbarPanel = 'add-element';
        openToolbarSubPanel = 'background-setting';
      } else if (data.tagName === 'IMG' || data.isLinkWrappedMedia) {
        openToolbarPanel = 'add-element';
        openToolbarSubPanel = 'selected-image-panel';
      } else if (data.tagName === 'A') {
        openToolbarPanel = 'add-element';
        openToolbarSubPanel = 'selected-button-panel';
      } else {
        setMakeOpenSubPanel({});
      }

      // Mostly for Style Inspector update
      const { elementSelector, rootNodeHost } = data;

      selected = {
        messageData: { data },
        elementSelector: elementSelector || rootNodeHost,
        openToolbarPanel,
        openToolbarSubPanel,
      };

      msg({
        type: 'get-selected-page-data',
        ...selected,
      });
    }

    if (data.type === 'receive-selected-page-data') {
      if (data.openToolbarPanel) {
        setOpenPanel(data.openToolbarPanel);
      }

      if (data.openToolbarSubPanel && data.openToolbarSubPanel !== 'background-setting') {
        setMakeOpenSubPanel({ type: data.openToolbarSubPanel, data: data.result });
      }

      if (data.openToolbarSubPanel && data.openToolbarSubPanel === 'background-setting') {
        setMakeOpenSubPanel({ type: data.openToolbarSubPanel, viewerData: data.viewerData });
        selected.openToolbarPanel = undefined;
        selected.openToolbarSubPanel = undefined;
      }
    }

    // We don't process the page data here because 'get/receive-selected-page-data'
    // would do that for us
    if (data.type === 'broadcast-history-change-page-data') {
      if (selected) {
        msg({
          type: 'get-selected-page-data',
          ...selected,
        });
      }
    }

    if (data.type === 'open-toolbar-panel') {
      setOpenPanel(data.openToolbarPanel);
      if (data.openToolbarSubPanel) {
        if (data.openToolbarSubPanel === 'background-setting') {
          selected.openToolbarPanel = 'add-element';
          selected.openToolbarSubPanel = 'background-setting';
          postMessagesToParent(selected.messageData.data, undefined, true);
        } else {
          setMakeOpenSubPanel({ type: data.openToolbarSubPanel });
        }
      }
    }

    if (['delete-page-elements', 'broadcast-history-change-page-data'].includes(data.type)) {
      prepareClosePanelAfterViewerFullyRefreshed = true;
    }

    if (
      (data.type === 'viewer-refreshed' && prepareClosePanelAfterViewerFullyRefreshed) ||
      data.type === 'close-toolbar-panel'
    ) {
      prepareClosePanelAfterViewerFullyRefreshed = false;
      setOpenPanel(undefined);
      setMakeOpenSubPanel({});
      selected = {
        messageData: { data: {} },
      };
    }
  }, []);

export default useToolbarOperations;
